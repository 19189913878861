/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import { ApolloProvider } from '@apollo/client'
import { FormLabel, FormSelect } from '@patchworkhealth/web-components'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Slide, ToastContainer } from 'react-toastify'
// import styled from 'styled-components'
import createApolloClient from '../createApolloClient'

document.addEventListener('DOMContentLoaded', () => {
  const client = createApolloClient()
  const config = { attributes: true, childList: true, subtree: true, characterData: true }

  const observer = new MutationObserver(() => {
    const rootElement = document.querySelector('[data-react-component="agency_selfbill"]')
    const mspType = rootElement?.getAttribute('mspType')

    if (rootElement && rootElement.childElementCount === 0) {
      ReactDOM.render(
        <>
          <ToastContainer position="top-center" transition={Slide} />
          <ApolloProvider client={client}>
            <AgencySelfBill mspType={mspType} />
          </ApolloProvider>
        </>,
        rootElement
      )
    }
  })
  observer.observe(document, config)
})

function AgencySelfBill({ mspType }) {
  const OPTIONS = [
    {
      value: 0,
      label: 'Non-MSP',
    },

    {
      value: 1,
      label: 'HCRG',
    },
    {
      value: 2,
      label: 'MEDACS',
    },
  ]

  const [value, setValue] = React.useState(null)

  useEffect(() => {
    if (mspType) {
      setValue(OPTIONS.find((option) => option.value === Number(mspType)))
    }
  }, [mspType])

  return (
    <div
      style={{
        position: 'relative',
        zIndex: 99999999,
      }}
    >
      <FormLabel>MSP Type: {mspType}</FormLabel>
      <FormSelect
        name="organisation[msp_type]"
        onChange={(option) => {
          setValue(option)
        }}
        options={OPTIONS}
        placeholder="Select MSP Type"
        value={value}
      />
    </div>
  )
}

export default AgencySelfBill
